export const technicalSkillsConfig = [
  "JavaScript",
  "TypeScript",
  "React",
  "Redux",  
  "NextJS",
  "Firebase",
  "TailwindCSS",
  "MaterialUI",
  
];
